import React from 'react'

const Contact = () => {
  return (
    <div className='container contact-container'>
        Email: info@homelychef.com
        Phone: 8299849412
        Address: Golden I, sector 1, Greater Noida West, 201309
    </div>
  )
}

export default Contact