import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const ErrorPage = () => {
  const location = useLocation();
  return (
    <>
    <div> {location.state?.message || 'Page not found'} </div>
    <button type='button'> <NavLink to='/'> Go to Home </NavLink></button>
    </>
  )
}

export default ErrorPage