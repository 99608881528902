import React from 'react'

const Apply = () => {
  return (
    <div>
        To Apply in Internship please give us a mail at info@homelychef.com or call us at 8299849412
    </div>
  )
}

export default Apply