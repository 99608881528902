import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error:'',
    user:{},
    loading:false,
    isVerified: false,
}

export const userSlice = createSlice({
    name:'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setIsVerified: (state, action) => {
            state.isVerified = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    }
})

export const {setLoading, setUser, setError, setIsVerified} = userSlice.actions;
export default userSlice.reducer;