import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {}
}

export const exampleSlice = createSlice({
    name: 'example',
    initialState,
    reducers:{
        getUser: (state, action) => {
            state.user = action.payload
        },

        increment: (state, action) => {
            state.count += 1
        },

        decrement: (state) => {
            state.count -= 1
        }
    }
})

export const {increment, decrement, getUser} = exampleSlice.actions;
export default exampleSlice.reducer