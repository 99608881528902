import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        logOut: (state) => {
            state.token = null
        }
    }
})

export default authSlice.reducer;
export const {setToken, logOut} = authSlice.actions;