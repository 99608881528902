import React, {useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { refreshToken } from '../utils/refreshToken';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../containers/Loader';
import { setIsVerified } from '../redux/user/userSlice';
import { setToken } from '../redux/auth/authSlice';

const PersistLogin = () => {
    const {token} = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const verifyRefreshToken = async() => {
            try{
              const {status, token} = await refreshToken();
              if(status === 200){
                dispatch(setToken(token));
                dispatch(setIsVerified(true));
              }
            }catch(err){
              console.log(err);
            }finally{
              setIsLoading(false);
            }
          }
          if(!token) verifyRefreshToken();
    }, [])


  return (
    <>
        {isLoading ? <Loader /> : <Outlet/>}
    </>
  )
}

export default PersistLogin