import React from "react";
import Heading from "../containers/Heading";

const Terms = () => {
  return (
    <div className="terms-container body-container">
      <Heading component={"h1"} content={"Terms & Conditions"} />
      <ul>
        <li>
          The customer shall pay the service charge fare for hiring the provided staff(chef or helper) as per the applicable rates
          and payment schedules mentioned on the website of HomelyChef at the
          time of booking. The customer is whole sole reponsible if he/she pay any extra amount directly to the staff.
        </li>
        <li>
          The payment has to be transferred to the HomelyChef account for the
          transaction to be completed. The client will have the responsibility
          to confirm the transaction with HomelyChef and send all relevant
          details and proof to confirm the same.
        </li>
        <li>
          In case of any dispute in transcation customer can raise the concern through the email info@homelychef.com .
        </li>
        <li>
          In case of a payment made before the commencement of a booking, the
          amount shall be treated as advance / part payment and the final amount
          nett of the advance paid shall be cleared by the client as per the
          amount intimated to the client on email.
        </li>
        <li>
          The Customer agrees and acknowledges that the use of the services
          offered by HomelyChef is at the sole risk of the Customer. HomelyChef disclaims all representations and warranties of any kind,
          whether express or implied as to condition, suitability, quality,
          merchantability and fitness of the services offered by HomelyChef.
          The liability of HomelyChef is excluded to the fullest extent
          permitted by law.
        </li>
        <li>
          The pricing rates are subject to change in the future.
        </li>
      </ul>
    </div>
  );
};

export default Terms;
