
// we will create fetch interceptors to generate accesstoken from refreshtoken:

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setToken } from "../redux/auth/authSlice";
import { refreshToken } from "../utils/refreshToken";

export const useFetchInterceptor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchApiInterceptors = async(url, token='', options={}) => {
    const headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      try{
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {...options, headers});
        const result = await response.json();
        if(response.status === 401){
            const {status, token} = await refreshToken();
            if(status === 200){
                dispatch(setToken(token));
                return fetchApiInterceptors(url, token, {...options, headers});
            }
            else{
                dispatch(setToken(null));
                return navigate('/login', {state: {message: 'Session Expired! Please login again!'}});
            }
        }
        return result;
      }catch(err){
        console.log('error', err);
        return navigate('/login', {state: {message: 'Session Expired! Please login again!'}});
      }
    }
    return fetchApiInterceptors
}